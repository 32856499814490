import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import MarketplacePage from './pages/MarketplacePage';
import ConnectPage from './pages/ConnectPage';
import HelpPage from './pages/Help';
import OurTeamPage from './pages/OurTeamPage';
import PressPage from './pages/PressPage';
import DownloadNowPage from './pages/DownloadNowPage';
import Header from './components/Common/Header/Header';
import EmailConfirmationPage from './pages/EmailConfirmationPage';
import Footer from './components/Common/Footer/Footer';
import ForgotPassword from './pages/ForgotPassword'; // Make sure to import the ForgotPassword page
import RegistrationSuccessful from './pages/RegistrationSuccessful.js';
import PrivacyPolicy from './pages/PrivacyPolicy.js'
import TermsOfService from './pages/TermsOfService.js'

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/marketplace" element={<MarketplacePage />} />
          <Route path="/connect" element={<ConnectPage />} />
          <Route path="/help" element={<HelpPage />} />
          <Route path="/ourteam" element={<OurTeamPage />} />
          <Route path="/press" element={<PressPage />} />
          <Route path="/downloadnow" element={<DownloadNowPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} /> {/* Link to ForgotPassword page */}
          <Route path="/confirm-signup" element={<EmailConfirmationPage />} /> 
          <Route path="/account-success" element={<RegistrationSuccessful />} /> 
          <Route path="/privacy-policy" element={<PrivacyPolicy />} /> 
          <Route path="/terms-of-service" element={<TermsOfService />} /> 
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
