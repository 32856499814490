import React from 'react';
import Footer from '../components/Common/Footer/Footer';
import PressGrid from '../components/Press/PressSection'

const PressPage = () => {
  return (
    <div>
        <PressGrid /> 
     
    </div>
  );
};

export default PressPage;