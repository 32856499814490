import React from 'react';

const RegistrationSuccessful = () => {
  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Registration Successful!</h2> {/* Same size and style as the "Reset Your Password" header */}
      <p style={styles.message}>
        Your registration was successful. You can now log in to your account.
      </p>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // Full height to ensure centering
    padding: '16px',
  },
  header: {
    fontSize: '32px', // Same size as "Reset Your Password"
    fontWeight: 'bold',
    color: '#333', // Darker color for the header
    marginBottom: '20px', // Add some space below the header
  },
  message: {
    fontSize: '18px', // Smaller text for the message
    color: '#666', // Lighter text color for the message
    textAlign: 'center', // Centered message
    maxWidth: '400px', // Limit the width for better readability
  },
};

export default RegistrationSuccessful;
