import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Privacy Policy</h1>
      <p style={styles.updatedDate}>Last Updated: 09/19/2024</p>

      <section style={styles.section}>
        <h2 style={styles.subheader}>Welcome to College Connector</h2>
        <p>
          Welcome to <strong>College Connector</strong>, a service provided by <strong>College Connector Network LLC</strong> ("we," "our," "us"). We are committed to protecting your privacy and ensuring the security of the information you share with us. This Privacy Policy outlines how we collect, use, store, and disclose your personal information when you use our platform.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subheader}>1. Information We Collect</h2>
        <p>
          When you use the <strong>College Connector</strong> app, we may collect the following types of information:
        </p>
        <ul>
          <li><strong>Personal Information:</strong> This includes your email address, name, and any other information required to create your account. This information is necessary to help us identify you and provide our services effectively.</li>
          <li><strong>User-Generated Content:</strong> Information you voluntarily submit to our platform, such as club and event details, is stored and managed by us.</li>
          <li><strong>Usage Data:</strong> We may collect information about how you interact with the app, including the pages or features you access.</li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subheader}>2. How We Use Your Information</h2>
        <p>The information we collect is used in the following ways:</p>
        <ul>
          <li>To provide and maintain the services offered by <strong>College Connector</strong>.</li>
          <li>To enable users to post and share information about university clubs and events.</li>
          <li>To communicate with you regarding your account or app updates.</li>
          <li>To comply with legal obligations or to respond to lawful requests from law enforcement.</li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subheader}>3. Data Storage and Security</h2>
        <p>
          All user data, including personal information and user-generated content, is securely stored using <strong>Supabase</strong>, a trusted data storage platform. We take all reasonable measures to protect your data from unauthorized access, disclosure, or alteration.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subheader}>4. Your Rights to Access and Control Your Information</h2>
        <p>
          You have the right to access and control the personal data you provide to us:
        </p>
        <ul>
          <li><strong>Data Access:</strong> You may request access to any personal data we hold about you. We will provide you with a copy of the information in a timely manner.</li>
          <li><strong>Data Deletion:</strong> You may request the deletion of your personal data. However, please note that some information may need to be retained to fulfill legal obligations or to continue providing our services.</li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subheader}>5. Marketplace Feature</h2>
        <p>
          In the future, we plan to introduce a marketplace feature where students can buy and sell items. Any additional information required for this feature will be handled in compliance with this Privacy Policy.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subheader}>6. Information Sharing</h2>
        <p>
          We do not sell or rent your personal information to third parties. However, we may share your information in the following situations:
        </p>
        <ul>
          <li>With service providers who assist us in operating the platform, as long as they comply with strict data protection policies.</li>
          <li>In response to a legal request or when required to comply with applicable laws.</li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subheader}>7. Updates to This Policy</h2>
        <p>
          We may update this Privacy Policy from time to time to reflect changes in our services or legal requirements. We will notify you of any significant changes by posting the updated policy on our app.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subheader}>8. Contact Us</h2>
        <p>
          If you have any questions or concerns regarding this Privacy Policy or how your data is handled, please contact us at:
        </p>
        <p>
          <strong>College Connector Network LLC</strong><br />
          Email: collegeconnectornh@gmail.com
        </p>
      </section>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    lineHeight: '1.6',
  },
  header: {
    fontSize: '32px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  updatedDate: {
    fontSize: '14px',
    color: '#888',
    marginBottom: '20px',
  },
  section: {
    marginBottom: '30px',
  },
  subheader: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
};

export default PrivacyPolicy;
