// src/pages/HomePage.js
import React from 'react';
import HeroSection from '../components/HomePage/HeroSection/HeroSection';
import InfoSection from '../components/HomePage/Card/InfoSection';
import Footer from '../components/Common/Footer/Footer';

const HomePage = () => {
  return (
    <div>
        <HeroSection />
        <InfoSection />
        
    </div>
  );
};

export default HomePage;
