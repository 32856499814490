import React from 'react';

const TermsOfService = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Terms of Service</h1>
      <p style={styles.updatedDate}>Last Updated: 09/19/2024</p>

      <section style={styles.section}>
        <h2 style={styles.subheader}>1. Acceptance of Terms</h2>
        <p>
          By using or accessing the College Connector app, operated by <strong>College Connector Network LLC</strong> ("we," "our," "us"), you agree to comply with and be bound by the following terms and conditions ("Terms"). If you do not agree to these Terms, you are prohibited from using the app.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subheader}>2. "Real Name" Policy</h2>
        <p>
          Users are required to provide their real, full name when signing up for an account on College Connector. False or misleading information, including but not limited to fake names or aliases, is prohibited. We reserve the right to verify your identity and to ban any accounts found to be in violation of this policy.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subheader}>3. Marketplace Rules and Fines</h2>
        <p>
          If you post items for sale or trade on our marketplace, you agree to only provide truthful and accurate descriptions of your items. Any false postings or attempts to scam other users may result in a fine of up to $1,000. We reserve the right to enforce this penalty and take legal action if necessary. By using our marketplace, you acknowledge and accept these terms.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subheader}>4. Account Suspension or Termination</h2>
        <p>
          We reserve the right to suspend or terminate your account at any time, for any reason, with or without prior notice. This includes, but is not limited to, violations of these Terms, fraudulent activities, or inappropriate behavior within the app. We also reserve the right to ban users from future access to the platform at our sole discretion.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subheader}>5. User Responsibilities</h2>
        <p>
          You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to immediately notify us of any unauthorized use of your account or any other breach of security.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subheader}>6. Limitation of Liability</h2>
        <p>
          To the fullest extent permitted by law, <strong>College Connector Network LLC</strong> shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your use or inability to use the app; (b) any unauthorized access to or use of our servers and/or any personal information stored therein; (c) any content obtained from the app; and (d) any user conduct or content, including offensive, inappropriate, or illegal behavior by any users.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subheader}>7. Changes to Terms</h2>
        <p>
          We may revise and update these Terms at any time. Your continued use of the College Connector app following the posting of any changes to these Terms constitutes your acceptance of the new Terms.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subheader}>8. Contact Us</h2>
        <p>
          If you have any questions about these Terms, please contact us at:
        </p>
        <p>
          <strong>College Connector Network LLC</strong><br />
          Email: collegeconnectornh@gmail.com
        </p>
      </section>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    lineHeight: '1.6',
  },
  header: {
    fontSize: '32px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  updatedDate: {
    fontSize: '14px',
    color: '#888',
    marginBottom: '20px',
  },
  section: {
    marginBottom: '30px',
  },
  subheader: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
};

export default TermsOfService;
